import type { ChangeEvent, PropsWithChildren, ReactElement, ReactEventHandler, SetStateAction } from "react";
import { Fragment, useCallback, useId, useState } from "react";
import type { DropzoneRootProps, DropzoneState, FileRejection } from "react-dropzone";
import Dropzone, { ErrorCode } from "react-dropzone";
import type { AwsS3Part } from "@uppy/aws-s3-multipart";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import type { FailedUppyFile, Restrictions, UploadResult, UppyFile } from "@uppy/core";
import { Uppy } from "@uppy/core";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constVoid, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as TE from "fp-ts/lib/TaskEither";
import { useStableEffect, useStableO } from "fp-ts-react-stable-hooks";
import type { OptionFromNullableC } from "io-ts-types";
import { Iso, Lens } from "monocle-ts";
import V from "voca";

import type { UploadedUppyFileResponse } from "@scripts/api/upload";
import {
  abortS3MultipartUpload,
  completeS3Upload,
  createS3Upload,
  listS3UploadParts,
  postS3UploadSuccess,
  presignS3UploadParts,
  uploadedUppyFileResponse,
} from "@scripts/api/upload";
import { type BLConfigWithLog, formatS3CdnUrl } from "@scripts/bondlink";
import type { RespOrErrors } from "@scripts/fetch";
import { RA } from "@scripts/fp-ts";
import type { MediaPreview, MediaUploadResponse, MediaUploadResponseC } from "@scripts/generated/models/media";
import type { S3AclU, S3MultipartUploadInfo } from "@scripts/generated/models/s3Upload";
import { s3PrivateAcl } from "@scripts/generated/models/s3Upload";
import {
  type ButtonActionProps,
  ButtonActionShort,
  ButtonActionTall,
  type ButtonActionTallProps,
  ButtonAsyncIcon,
  ButtonLink,
  type ButtonProps,
} from "@scripts/react/components/Button";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { UploadArea } from "@scripts/react/components/form/Uploader";
import { useConfig } from "@scripts/react/context/Config";
import type {
  DataCodec,
  StateProps,
  UnsafeFormData,
  UnsafeFormProp,
  ValErrMsgA,
  ValErrMsgAR,
} from "@scripts/react/form/form";
import {
  clearAndSetState,
  flattenValErr,
  getValErr,
  getValue,
  hasErrors,
  updateAndSetState,
} from "@scripts/react/form/form";
import { mimeToAccept } from "@scripts/react/syntax/dropzone";
import { klass } from "@scripts/react/util/classnames";
import { generateKey } from "@scripts/uploader/helpers";
import type { MimeToExt } from "@scripts/uploader/mimeTypes";
import {
  allowedDocMimeTypes,
  MimeError,
  mimeToExt,
  mimeToHtmlInputAccept,
  verifyFileType,
} from "@scripts/uploader/mimeTypes";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";
import { uniqueAriaId } from "@scripts/util/labelOrAriaLabel";
import { traceError } from "@scripts/util/log";
import { prop } from "@scripts/util/prop";
import { tap } from "@scripts/util/tap";

import checkmarkIcon from "@svgs/checkmark.svg";
import documentIcon from "@svgs/document.svg";
import uploadIcon from "@svgs/upload.svg";

import type { Target } from "../Anchor";
import { SvgWithText } from "../Svg";
import type { TooltipProps } from "../Tooltip";
import { errorMsgEl, LabelEl, valErrMsgElsO } from "./Labels";
import { InputRaw, TextInputLabelRaw } from "./TextInput";

/*
  # UPPY S3 MultiPart Workflow

  ## Multipart upload steps:

  1. `POST` request to backend `createMultipartUpload` endpoint -- tells S3 about a new multipart upload
      1. Input: `S3CreateMultipartUploadPost`
      2. Output: `S3MultipartUploadInfo`
  2. `POST` request to backend `prepareUploadParts` endpoint -- generates presigned URLs for each part to be uploaded to S3
      1. Input: `S3PresignMultipartUploadPartsPost`
      2. Output: `S3PresignedMultipartUploadParts`
  3. `PUT` request for each part to presigned S3 URL
      1. Input/output types are irrelevant, this is 100% managed by uppy
  4. `POST` request to backend `completeMultipartUpload` endpoint -- tells S3 the multipart upload is complete and the object should exist fully in S3
      1. Input: `S3CompleteMultipartUploadPost`
      2. Output: `S3CompleteMultipartUploadResponse`

  ## Other pieces

  - Backend `listMultipartUpload` endpoint
    - Allows for listing existing parts when resuming a multipart upload
    - Not currently used, but required by uppy's options
  - Backend `abortMultipartUpload` endpoint
    - Allows for aborting a multipart upload, e.g. if the user explicitly cancels it
    - Not currently used, but required by uppy's options
*/

const urL = Lens.fromProp<UnsafeFormData<MediaUploadResponseC>>();
const urIso = new Iso<UnsafeFormProp<UnsafeFormData<MediaUploadResponseC>>, UnsafeFormData<MediaUploadResponseC>>(s => s ?? {}, identity);

export type UploaderConfigProps = {
  bucket: string;
  clientId: number;
};

export type UploadWrapperProps = UploaderConfigProps & {
  restrictions?: Restrictions;
  editMode?: boolean;
};

type S3UploadResponse = [MediaPreview, MediaUploadResponse];
export type AclPermission = S3AclU;

type FileUploadParams = {
  file: File;
  key: string;
  name: string;
  acl: AclPermission;
};

type UploadError = { kind: "docType", error: Error }
  | { kind: "fileCount", error: string }
  | { kind: "fileSize", error: string }
  | { kind: "uppyFail", error: FailedUppyFile<object, object> }
  | { kind: "s3Upload", error: RespOrErrors }
  | { kind: "policyFetch", error: RespOrErrors }
  | { kind: "general", error: unknown }
  | { kind: "mimeType", error: MimeError };

export type InputState = {
  error: O.Option<UploadError>;
  progress: O.Option<{
    bytesUploaded: number; bytesTotal: number;
  }>;
};

const isDocTypeError = (err: Error): boolean => err.message.includes("You can only upload:");

const defaultErrMsg = "There was a problem uploading the file. Please try again.";
const fileCountErrMsg = "Please select a single file and try again.";
const docTypeErrMsg = `You may only upload documents with extension types:
  ${pipe(allowedDocMimeTypes, RA.chain(_ => _.exts)).join(", ")}`;

const getDisplayMessage = (config: BLConfigWithLog) => (err: UploadError): string => {
  switch (err.kind) {
    case "docType":
      return docTypeErrMsg;
    case "fileCount":
      return fileCountErrMsg;
    case "fileSize":
      return err.error;
    case "uppyFail":
      return defaultErrMsg;
    case "s3Upload":
      return defaultErrMsg;
    case "policyFetch":
      return defaultErrMsg;
    case "general":
      return defaultErrMsg;
    case "mimeType":
      return err.error.displayError;
  }
  return config.exhaustive(err);
};

export const mediaOptionToMediaIso = new Iso<UnsafeFormProp<UnsafeFormData<OptionFromNullableC<MediaUploadResponseC>>>, UnsafeFormProp<UnsafeFormData<MediaUploadResponseC>>>(
  flow(fromNullableOrOption, O.toUndefined), O.fromNullable
);

export const getFileErr = <PC extends DataCodec>(state: MediaStateProps<PC>["state"], lens: MediaStateProps<PC>["lens"], config: BLConfigWithLog) => {
  const fileL = <K extends keyof MediaUploadResponse>(k: K) => lens.composeIso(urIso).compose(urL(k));
  const veFile = flattenValErr(getValErr(state, lens)(config).err);
  const veUri = flattenValErr(getValErr(state, fileL("uri"))(config).err);
  const veFilesize = flattenValErr(getValErr(state, fileL("fileSize"))(config).err);

  return [...veFile, ...veUri, ...veFilesize];
};

export type UploadComponentProps = {
  acl: AclPermission;
  allowedMimeTypes: ReadonlyArray<MimeToExt>;
  setError: (err: O.Option<string>) => void;
  loading?: boolean;
  onUploadStart?: () => void;
  onError?: () => void;
};

export type S3UploaderWrapperChildren = { children: (s3p: S3UploaderChildrenPayload) => ReactElement };
export type HandleDropS3 = (allowedMimeTypes: ReadonlyArray<MimeToExt>, onSuccess: (f: File[]) => void) => (files: File[], rejections: FileRejection[]) => void;

export type S3UploaderChildrenPayload = {
  handleError: (err: UploadError) => void;
  handleDrop: HandleDropS3;
  setUploadState: (value: SetStateAction<InputState>) => void;
  isUploading: boolean;
  uploadFileToS3: (fileKey: O.Option<string>) => (file: File) => TE.TaskEither<UploadError, S3UploadResponse>;
};

export const S3UploaderWrapper = (props: UploadComponentProps & UploadWrapperProps & S3UploaderWrapperChildren) => {
  const config = useConfig();
  const [uploadState, setUploadState] = useState<InputState>({ error: O.none, progress: O.none });
  const [isUploading, setIsUploading] = useState(false);

  // create selector for current input state
  const handleProgress = (__file: { name: string } | undefined, progress: { bytesUploaded: number, bytesTotal: number }) => {
    setUploadState({ ...uploadState, progress: O.some(progress) });
  };

  const handleError: (e: UploadError) => UploadError =
    tap(e => setUploadState({ error: O.some(e), progress: O.none }));

  const handleDrop = (allowedMimeTypes: ReadonlyArray<MimeToExt>, onSuccess: (f: File[]) => void) => (files: File[], rejections: FileRejection[]) => {
    setUploadState({ error: O.none, progress: O.none });
    if (rejections[0]) {
      const fileExt = rejections[0].file.name.slice(rejections[0]?.file.name.lastIndexOf(".") + 1).toLowerCase();
      const allowedExt = mimeToExt(allowedMimeTypes);

      switch (rejections[0]?.errors[0]?.code) {
        case ErrorCode.FileInvalidType: {
          handleError({
            kind: "mimeType",
            error: new MimeError(
              `File type “${rejections[0]?.file.type}” is not a supported upload extension`,
              `“${fileExt}” is not a supported file type. Accepted types: ${allowedExt.join(", ")}`
            ),
          });
          return;
        }
        case ErrorCode.TooManyFiles: {
          handleError({ kind: "fileCount", error: "handleDrop: More than 1 file selected" });
          return;
        }
        case ErrorCode.FileTooLarge: {
          handleError({ kind: "fileSize", error: "File size is too large" });
          return;
        }
        case ErrorCode.FileTooSmall: {
          handleError({ kind: "fileSize", error: "File size is too small" });
          return;
        }
        // eslint-disable-next-line no-undefined
        case undefined:
        default: {
          handleError({ kind: "general", error: rejections[0]?.errors[0]?.code });
          return;
        }
      }
    } else if (files.length > 1) {
      handleError({ kind: "fileCount", error: "handleDrop: More than 1 file selected" });
    } else {
      onSuccess(files);
    }
  };

  const postS3Success = (uppyFile: UploadedUppyFileResponse): TE.TaskEither<UploadError, S3UploadResponse> => {
    const body = {
      key: uppyFile.response.body.key,
      bucket: uppyFile.response.body.bucket,
      size: uppyFile.size,
    };

    return pipe(
      postS3UploadSuccess(config)(body),
      TE.bimap(
        (e: RespOrErrors) => ({ kind: "s3Upload", error: e }),
        (d: { data: MediaPreview }): S3UploadResponse => [d.data, {
          uri: d.data.uri,
          viewName: uppyFile.name,
          fileSize: uppyFile.size,
        }]
      )
    );
  };

  const unsafeTaskEitherToPromise = <E, A>(te: TE.TaskEither<E, A>): Promise<A> =>
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    te().then(E.fold(e => Promise.reject(e), a => Promise.resolve(a)));

  const multipartUploadInfo = ({ key, uploadId }: { key: string, uploadId: string }): S3MultipartUploadInfo => ({
    bucket: props.bucket,
    key,
    uploadId,
  });

  const uppyUpload = (f: FileUploadParams) => {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
      },
      allowMultipleUploads: false,
    });
    uppy.addFile({
      name: f.file.name,
      type: f.file.type,
      data: f.file,
    });
    uppy.use(AwsS3Multipart, {
      createMultipartUpload: (): Promise<{ uploadId: string, key: string }> => {
        return unsafeTaskEitherToPromise(pipe(
          createS3Upload(config)({
            bucket: props.bucket,
            acl: f.acl,
            key: f.key,
            contentType: f.file.type,
            fileName: f.file.name,
          }),
          TE.bimap(
            (e): UploadError => ({ kind: "s3Upload", error: e }),
            d => ({ uploadId: d.data.uploadId, key: f.key }),
          )
        ));
      },

      prepareUploadParts: (
        __file: UppyFile,
        // eslint-disable-next-line id-blacklist
        partData: { uploadId: string, key: string, parts: [{ number: number, chunk: Blob }] }
      ): Promise<{ presignedUrls: Record<number, string> }> => {
        return unsafeTaskEitherToPromise(pipe(
          presignS3UploadParts(config)({
            uploadInfo: multipartUploadInfo(partData),
            partNumbers: partData.parts.map(prop("number")),
          }),
          TE.bimap(
            (e): UploadError => ({ kind: "policyFetch", error: e }),
            (d) => ({ presignedUrls: Object.fromEntries(d.data.presignedUrls) })
          ),
        ));
      },

      // The return type of this function in uppy's types is just `{ location?: string }`,
      // but we need the additional fields downstream to pass to our S3 success endpoint
      completeMultipartUpload: (
        __file: UppyFile,
        opts: { uploadId: string, key: string, parts: AwsS3Part[] }
      ): Promise<UploadedUppyFileResponse["response"]["body"]> => {
        return unsafeTaskEitherToPromise(pipe(
          RNA.fromArray(opts.parts),
          O.chain(RNA.traverse(O.Applicative)(part => pipe(
            O.Do,
            O.apS("partNumber", O.fromNullable(part.PartNumber)),
            O.apS("etag", O.fromNullable(part.ETag)),
            O.map(x => ({ ...x, size: O.fromNullable(part.Size) })),
          ))),
          O.fold(
            () => TE.throwError<UploadError, UploadedUppyFileResponse["response"]["body"]>(
              { kind: "general", error: ["Failed to construct list of upload parts", opts] }
            ),
            parts => pipe(
              completeS3Upload(config)({
                uploadInfo: multipartUploadInfo(opts),
                parts: parts,
              }),
              TE.bimap(
                (e): UploadError => ({ kind: "s3Upload", error: e }),
                d => ({
                  bucket: props.bucket,
                  key: f.key,
                  location: d.data.location,
                })
              ),
            )
          ),
        ));
      },

      listParts: (__file: UppyFile, opts: { uploadId: string, key: string }): Promise<AwsS3Part[]> => {
        return unsafeTaskEitherToPromise(pipe(
          listS3UploadParts(config)({ uploadInfo: { ...opts, bucket: props.bucket } }),
          TE.bimap(
            (e): UploadError => ({ kind: "s3Upload", error: e }),
            d => d.data.parts.map((p): AwsS3Part => ({
              PartNumber: p.partNumber,
              Size: O.toUndefined(p.size),
              ETag: p.etag,
            }))
          ),
        ));
      },

      abortMultipartUpload: (__file: UppyFile, opts: { uploadId: string, key: string }): Promise<void> => {
        return unsafeTaskEitherToPromise(pipe(
          abortS3MultipartUpload(config)(multipartUploadInfo(opts)),
          TE.bimap(
            (e): UploadError => ({ kind: "s3Upload", error: e }),
            constVoid,
          ),
        ));
      },
    });
    uppy.on("upload-progress", handleProgress);
    return uppy;
  };

  const uploadFile = (selectedFile: FileUploadParams): TE.TaskEither<UploadError, S3UploadResponse> =>
    pipe(
      verifyFileType(props.allowedMimeTypes)(selectedFile.file),
      tap(() => setIsUploading(true)),
      TE.mapLeft((e): UploadError => ({ kind: "mimeType", error: e })),
      TE.chain(() => TE.tryCatch(
        () => uppyUpload(selectedFile).upload(),
        (e: unknown): UploadError => e instanceof Error && isDocTypeError(e)
          ? { kind: "docType", error: e }
          : { kind: "general", error: e }
      )),
      TE.filterOrElse(
        (d: UploadResult | void): d is UploadResult => typeof d === "object",
        (): UploadError => ({ kind: "general", error: "Undefined UploadResult" })
      ),
      TE.filterOrElse(
        d => d.failed.length === 0 && d.successful.length > 0,
        d => d.failed.reduce<UploadError>(
          (__acc, data) => ({ kind: "uppyFail", error: data }),
          { kind: "general", error: `Uppy failed to upload and produced no failures` }
        )),
      TE.bimap(
        tap(() => setIsUploading(false)),
        tap(() => setIsUploading(false)),
      ),
      TE.chain((d: UploadResult): TE.TaskEither<UploadError, S3UploadResponse> =>
        d.successful.reduce(
          (__acc, data): TE.TaskEither<UploadError, S3UploadResponse> => pipe(
            // Confirm response includes data we need to produce s3/success POST request
            TE.fromEither(uploadedUppyFileResponse.decode(data)),
            TE.mapLeft((e): UploadError => ({ kind: "general", error: e })),
            TE.chain(r => postS3Success({ ...r, name: selectedFile.file.name }))
          ),
          TE.left<UploadError, S3UploadResponse>({ kind: "general", error: "No successful file uploads" })
        )
      ),
    );

  useStableEffect(() => {
    pipe(uploadState.error, O.map(getDisplayMessage(config)), props.setError);
    if (O.isSome(uploadState.error) && props.onError) props.onError();
    return () => props.setError(O.none);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadState.error], Eq.tuple(O.getEq(Eq.eqStrict)));

  const uploadFileToS3 =
    (key: O.Option<string>) =>
      (file: File): TE.TaskEither<UploadError, S3UploadResponse> => {
        const fileKey = O.getOrElse(() => generateKey(O.none)(file, props.clientId))(key);

        return pipe(
          uploadFile({
            acl: props.acl,
            file: file,
            key: fileKey,
            name: file.name,
          }),
          TE.mapLeft(traceError("Media failed to upload to S3")),
          TE.mapLeft(tap(handleError))
        );
      };

  return <Fragment>
    {props.children({ setUploadState, isUploading, uploadFileToS3, handleError, handleDrop })}
  </Fragment>;
};

type OnInitiate = {
  onInitiate: (acceptedFile: S3UploadResponse) => void;
};

type S3UploaderDocumentChildrenPayload = S3UploaderChildrenPayload & OnInitiate;

type DropzoneChildProps = DropzoneState & {
  acceptedMimeTypes: ReadonlyArray<MimeToExt>;
  isUploading: boolean;
};

type DropzoneChild = (props: DropzoneChildProps) => ReactElement;

const DropzoneChildUploadArea = (props: DropzoneChildProps) =>
  <div {...props.getRootProps({ ...klass("dropzone", "align-items-center", "justify-content-center") })}>
    <UploadArea inProgress={props.isUploading}>
      <input {...props.getInputProps()} accept={mimeToHtmlInputAccept(props.acceptedMimeTypes)} />
    </UploadArea>
  </div>;

const DropzoneChildButton = (props: DropzoneChildProps & { disabledOverride: boolean, text: string }) => {
  const btnText = `Upload ${props.text}`;

  const onClick = pipe(
    O.fromNullable(props.getRootProps<DropzoneRootProps>({}).onClick),
    O.getOrElse((): ReactEventHandler => constVoid)
  );
  return (
    <>
      <ButtonAsyncIcon
        loading={props.isUploading}
        disabled={props.isUploading || props.disabledOverride}
        loadingText="Uploading"
        variant="secondary"
        onClick={onClick}
        icon={uploadIcon}
        {...klass("mt-0")}
      >
        {btnText}
      </ButtonAsyncIcon>
      <input {...props.getInputProps()} accept={mimeToHtmlInputAccept(props.acceptedMimeTypes)} />
    </>
  );
};

const UploadStatus = (props: PropsWithChildren<{ isUploading: boolean }>) =>
  <div {...klass("upload-status", "d-flex", "flex-col", "align-items-end")}>
    <SvgWithText
      containerKlasses={[`${props.isUploading ? "yellow" : "green"}-700`]}
      prefixIcon={props.isUploading ? uploadIcon : checkmarkIcon}
      text={`Upload${props.isUploading ? "ing" : "ed"}`}
      textKlasses={["font-sans-normal-500"]}
    />
    {props.children}
  </div>;

const S3UploaderDropZone = (props: S3UploaderDocumentChildrenPayload & {
  acceptedMimeTypes: ReadonlyArray<MimeToExt>;
  onUploadStart?: () => void;
  dropZoneChild: DropzoneChild;
}) => {

  const onDropSuccess = (files: File[]) => {
    if (props.onUploadStart) props.onUploadStart();
    pipe(
      A.head(files),
      O.map(f => pipe(
        props.uploadFileToS3(O.none)(f),
        TE.map(props.onInitiate),
      )())
    );
  };

  return (
    <Dropzone
      onDrop={props.handleDrop(props.acceptedMimeTypes, onDropSuccess)}
      accept={mimeToAccept(props.acceptedMimeTypes)}
    >
      {(dropzoneProps) =>
        <props.dropZoneChild
          {...dropzoneProps}
          acceptedMimeTypes={props.acceptedMimeTypes}
          isUploading={props.isUploading}
        />
      }
    </Dropzone>
  );
};

type DropZoneUploaderProps = UploaderConfigProps & UploadComponentProps & OnInitiate & {
  dropZoneChild: DropzoneChild;
};

const DropZoneUploader = (props: DropZoneUploaderProps) =>
  <S3UploaderWrapper {...props}>
    {(cp) =>
      <S3UploaderDropZone
        {...cp}
        isUploading={props.loading || cp.isUploading}
        onInitiate={props.onInitiate}
        onUploadStart={props.onUploadStart}
        acceptedMimeTypes={props.allowedMimeTypes}
        dropZoneChild={props.dropZoneChild}
      />
    }
  </S3UploaderWrapper>;

const hasUri = (media: O.Option<UnsafeFormData<MediaUploadResponseC>>) => pipe(media, O.filterMap(_ => O.fromNullable(_.uri)), O.isSome);

type LabelData = { label: string, required: boolean };

type UploaderSharedProps = {
  label: O.Option<LabelData>;
  labelTooltip?: TooltipProps;
  disabled?: boolean;
  editMode?: boolean;
  onFileClick?: () => void;
} & Omit<UploadComponentProps, "setError">;

export type UploaderBaseProps = {
  media: O.Option<UnsafeFormData<MediaUploadResponseC>>;
  onActionClick: () => void;
  onFileChanged: (file: MediaUploadResponse, preview: MediaPreview) => void;
  fileErr: ValErrMsgA;
  labelActionEl: O.Option<ReactElement>;
  errMsgOnLabel?: boolean;
} & UploaderConfigProps & UploaderSharedProps;

const UploaderWrapper = (props: UploaderBaseProps & {
  children: (props: Pick<DropZoneUploaderProps, "setError" | "onInitiate">) => ReactElement;
}) => {
  const dropZoneErrorKey = useId();
  const formErrorKey = useId();
  const [error, setError] = useStableO<string>(O.none);

  const onInitiate = useCallback(([preview, uploadResp]: S3UploadResponse) => {
    setError(O.none);
    const viewName = pipe(
      props.media,
      O.chain(c => O.fromNullable(c.viewName)),
      O.filter(d => d.length > 0),
      O.getOrElse(() => V.replace(uploadResp.viewName, /\.[a-z\d]+$/, "").replace(/_/g, " ").replace(/\s+/g, " ").trim()),
    );
    props.onFileChanged({ ...uploadResp, viewName }, { ...preview, viewName });
  }, [props, setError]);

  const fileErrors = hasUri(props.media) ? [] : props.fileErr;
  return (
    <div {...klass("form-input", "document-upload", (hasErrors(E.right(fileErrors)) || O.isSome(error)) ? "has-danger" : "")}>
      <div className="d-flex flex-col">
        {pipe(
          props.label,
          mapOrEmpty(({ label, required }) => <LabelEl label={label} id={uniqueAriaId(label)} required={required} tooltip={props.labelTooltip} />)
        )}
        {pipe(props.labelActionEl, mapOrEmpty(identity))}
        {trueOrEmpty(errorMsgEl(`A response is required`, formErrorKey))(fileErrors.length > 0 && (props.errMsgOnLabel ?? false))}
      </div>
      {props.children({ onInitiate, setError })}
      {mapOrEmpty((_: string) => errorMsgEl(_, dropZoneErrorKey))(error)}
      {trueOrEmpty(errorMsgEl(`A response is required`, formErrorKey))(fileErrors.length > 0 && (!props.errMsgOnLabel))}
    </div>
  );
};

type UnsafeFormDataMediaUploadResponseNonNullUri = UnsafeFormData<MediaUploadResponseC> & { uri: string };

export const unsafeFormDataMediaUploadResponseOHasUri =
  O.filter((_: UnsafeFormData<MediaUploadResponseC>): _ is UnsafeFormDataMediaUploadResponseNonNullUri =>
    typeof _.uri === "string" && _.uri.length > 0
  );

type UploaderBaseAreaProps = UploaderBaseProps & Pick<ButtonActionTallProps, "action">;

export const UploaderBaseArea = (props: UploaderBaseAreaProps) => {
  return (
    <UploaderWrapper {...props}>
      {(_) =>
        pipe(
          unsafeFormDataMediaUploadResponseOHasUri(props.media),
          O.fold(
            () => <DropZoneUploader
              setError={_.setError}
              onInitiate={_.onInitiate}
              acl={props.acl}
              clientId={props.clientId}
              bucket={props.bucket}
              allowedMimeTypes={props.allowedMimeTypes}
              loading={props.loading}
              onUploadStart={props.onUploadStart}
              onError={props.onError}
              dropZoneChild={DropzoneChildUploadArea}
            />,
            (d) => <div>
              <ButtonActionTall
                {...klass("btn-action-upload")}
                icon={{ type: "svg", svg: documentIcon }}
                action={props.action}
                onClick={props.onFileClick || constVoid}
                onActionClick={props.onActionClick}
                contentName={d.viewName || "No filename provided"}
                contentMeta={O.none}
                disabled={props.disabled}
                disableHover={false}
              />
            </div>
          )
        )
      }
    </UploaderWrapper>
  );
};

const UploaderBaseButtonBase = (props: UploaderBaseProps & {
  children: (contentName?: string) => ReactElement;
  text: string;
}) => {

  const DropzoneChild = useCallback((p: DropzoneChildProps) => (
    <DropzoneChildButton {...p} disabledOverride={props.disabled ?? false} text={props.text} />
  ), [props.text, props.disabled]);

  return (
    <UploaderWrapper {...props}>
      {(_) => pipe(
        unsafeFormDataMediaUploadResponseOHasUri(props.media),
        O.fold(
          () => <DropZoneUploader
            setError={_.setError}
            onInitiate={_.onInitiate}
            acl={props.acl}
            clientId={props.clientId}
            bucket={props.bucket}
            allowedMimeTypes={props.allowedMimeTypes}
            loading={props.loading}
            onUploadStart={props.onUploadStart}
            onError={props.onError}
            dropZoneChild={DropzoneChild}
          />,
          (d) => props.children(d.viewName)
        )
      )}
    </UploaderWrapper>
  );
};

export const UploaderBaseButton = (props: UploaderBaseProps & { action?: ButtonActionProps["action"] }) =>
  <UploaderBaseButtonBase {...props} text={"Template"}>
    {(contentName) => {
      const sharedProps: Pick<ButtonActionProps, "action" | "contentName" | "onActionClick"> = {
        action: props.action ?? "delete",
        contentName: contentName || "No filename provided",
        onActionClick: props.onActionClick,
      };
      return pipe(
        O.fromNullable(props.onFileClick),
        O.fold(
          () => <ButtonActionShort {...sharedProps} onClick={constVoid} disableHover={true} />,
          onFileClick => <ButtonActionShort {...sharedProps} onClick={onFileClick} />
        )
      );
    }}
  </UploaderBaseButtonBase>;

type OpenDocument = (config: BLConfigWithLog, uri: string, target: Target) => void;

export const openDocument: OpenDocument = (config: BLConfigWithLog, uri: string, target: Target) => {
  globalThis.open(formatS3CdnUrl(config)(uri), target);
};

const ViewDocumentButtonLink = (props: Pick<ButtonProps, "disabled"> & {
  media: UnsafeFormDataMediaUploadResponseNonNullUri;
  openDocument: OpenDocument;
}) => {
  const config = useConfig();
  const onClick = useCallback(() => {
    props.openDocument(config, props.media.uri, "_blank");
  }, [config, props]);

  return (
    <ButtonLink disabled={props.disabled} onClick={onClick}>
      View
    </ButtonLink>
  );
};

export const UploaderBaseButtonLink = (props: Omit<UploaderBaseProps, "onActionClick"> & {
  onDelete: () => void;
  isEditable: boolean;
  openDocument: OpenDocument;
}) => {
  const dropzoneChildButton = useCallback((p: DropzoneChildProps) => (
    <div className="btn-dropzone-wrapper">
      <DropzoneChildButton {...p} disabledOverride={!props.isEditable} text={"File"} />
    </div>
  ), [props.isEditable]);
  const dropzoneChildViewAndDelete = useCallback((media: UnsafeFormDataMediaUploadResponseNonNullUri) => {
    return (p: DropzoneChildProps) => (
      <div className="upload-status-wrapper">
        <UploadStatus isUploading={p.isUploading}>
          <div {...klass("d-flex", "justify-content-between", "w-100")}>
            <ViewDocumentButtonLink media={media} openDocument={props.openDocument} disabled={p.isUploading} />
            <ButtonLink
              {...klass("mt-0")}
              disabled={p.isUploading || !props.isEditable}
              onClick={props.onDelete}
            >
              Delete
            </ButtonLink>
          </div>
        </UploadStatus>
      </div>
    );
  }, [props.openDocument, props.onDelete, props.isEditable]);

  const DropZone = useCallback((p: Pick<DropZoneUploaderProps, "setError" | "onInitiate" | "dropZoneChild">) =>
    <DropZoneUploader
      setError={p.setError}
      onInitiate={p.onInitiate}
      acl={props.acl}
      clientId={props.clientId}
      bucket={props.bucket}
      allowedMimeTypes={props.allowedMimeTypes}
      loading={props.loading}
      onUploadStart={props.onUploadStart}
      onError={props.onError}
      dropZoneChild={p.dropZoneChild}
    />,
    [props.acl, props.allowedMimeTypes, props.bucket, props.clientId, props.loading, props.onError, props.onUploadStart]);

  return (
    <UploaderWrapper {...props} onActionClick={constVoid}>
      {(_) => pipe(
        unsafeFormDataMediaUploadResponseOHasUri(props.media),
        O.fold(
          () => <DropZone
            setError={_.setError}
            onInitiate={_.onInitiate}
            dropZoneChild={dropzoneChildButton}
          />,
          (media) =>
            <div className="d-flex">
              {props.isEditable
                ? (
                  <DropZone
                    setError={_.setError}
                    onInitiate={_.onInitiate}
                    dropZoneChild={dropzoneChildViewAndDelete(media)}
                  />
                ) : (
                  <UploadStatus isUploading={false}>
                    <ViewDocumentButtonLink media={media} openDocument={props.openDocument} />
                  </UploadStatus>
                )
              }
            </div>
        )
      )}
    </UploaderWrapper>
  );
};

const documentUploaderLabel = "Document Title";

type DocumentUploaderProps = Omit<UploaderBaseAreaProps, "acl"> & {
  onFileNameChange: (value: string) => void;
  fileName: O.Option<string>;
  fileNameErr: ValErrMsgAR<string>;
};

function DocumentUploader(p: DocumentUploaderProps): ReactElement {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => p.onFileNameChange(e.target.value), [p.onFileNameChange]);
  const fileNameErrors = (hasUri(p.media) || O.isSome(p.fileName)) ? p.fileNameErr : E.right(p.fileErr);

  return <>
    <UploaderBaseArea
      {...p}
      acl={s3PrivateAcl}
    />
    <div {...klass("form-input", hasErrors(fileNameErrors) ? "has-danger" : "")}>
      <InputRaw
        labelOrAriaLabel={E.left(documentUploaderLabel)}
        labelComponent={O.some(<TextInputLabelRaw id={documentUploaderLabel} required={true} label={documentUploaderLabel} />)}
        ariaLabelledById={documentUploaderLabel}
        errorComponent={valErrMsgElsO(O.some(documentUploaderLabel), O.none)(fileNameErrors)}
        placeholder={O.none}
        type="text"
        onChange={onChange}
        value={p.fileName}
      />
    </div>
  </>;
}

type MediaStateProps<PC extends DataCodec> = StateProps<PC, UnsafeFormData<MediaUploadResponseC>>;

export type FormDocumentUploaderProps<PC extends DataCodec> =
  UploaderSharedProps
  & UploaderConfigProps
  & MediaStateProps<PC>
  & Pick<DocumentUploaderProps, "action">;

const formDocumentUploadOnFileChanged = <PC extends DataCodec>(
  setState: FormDocumentUploaderProps<PC>["setState"],
  lens: FormDocumentUploaderProps<PC>["lens"],
) => (m: MediaUploadResponse) => updateAndSetState(setState, lens)(m);

export function FormDocumentUploader<PC extends DataCodec>(p: FormDocumentUploaderProps<PC>): ReactElement {
  const config = useConfig();
  const lens = p.lens.composeIso(urIso).compose(urL("viewName"));

  const media = getValue(p.state, p.lens);
  const veFileName = getValErr(p.state, lens)(config);
  const fileErr = getFileErr(p.state, p.lens, config);
  const onFileRemoved = useCallback(() => clearAndSetState(p.setState, p.lens), [p.setState, p.lens]);
  const onDownloadFile = pipe(
    media,
    O.fold(
      () => constVoid,
      m => pipe(m.uri, O.fromNullable, O.fold(() => () => constVoid, _ => () => openDocument(config, _, "_blank")))
    )
  );

  return (
    <DocumentUploader
      {...p}
      media={media}
      allowedMimeTypes={p.allowedMimeTypes}
      onFileChanged={formDocumentUploadOnFileChanged(p.setState, p.lens)}
      onActionClick={p.action === "download" ? onDownloadFile : onFileRemoved}
      fileErr={fileErr}
      fileName={getValue(p.state, lens)}
      fileNameErr={veFileName.err}
      onFileNameChange={updateAndSetState(p.setState, lens)}
      labelActionEl={O.none}
    />
  );
}
