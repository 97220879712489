
import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, O, pipe, RA } from "@scripts/fp-ts";
import * as bs from "@scripts/generated/domaintables/biddingStates";
import { activeRfps, archivedRfps } from "@scripts/generated/domaintables/featureFlags";
import type { BidSubmissionData } from "@scripts/generated/models/bidSubmissions";
import type { BidSubmissionTemplateData } from "@scripts/generated/models/bidSubmissionTemplates";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer, WithIssuer } from "@scripts/generated/models/issuer";
import type { GenRfpWithRelatedContent, GenRfpWithRelatedContentC, RfpCardRelatedContent, RfpRelatedContent, RfpRelatedContentC, RfpSitesRelatedContent, RfpWithBidSubmissionsAndParticipants } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithModInfo, WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import type { Joda } from "@scripts/syntax/date/joda";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";

import { localDateNow } from "./date/joda";
import { isFFEnabled } from "./featureFlags";

export type RfpWithRelatedContent = GenRfpWithRelatedContent<RfpRelatedContent>;
export type RfpWithRelatedContentC = GenRfpWithRelatedContentC<RfpRelatedContentC>;

export type RfpWithSitesRelatedContent = GenRfpWithRelatedContent<RfpSitesRelatedContent>;
export type RfpWithCardRelatedContent = GenRfpWithRelatedContent<RfpCardRelatedContent>;

export type RfpWithIssuerBidSubmissionsParticipants = WithIssuer<WithStatusU<WithModInfo<RfpWithBidSubmissionsAndParticipants>>>;

export const rfpUrl = (rfpId: number, issuer: Issuer, config: BLConfigWithLog) =>
  issuerSiteAbsUrl(config)(issuer, O.none)(
    SitesRouter.issuersitesRfpsControllerRfp
  )({ rfpId });

export const rfpToFF = (rfp: Rfp) => rfp.biddingState._tag === "BidAwarded" ? archivedRfps : activeRfps;

export const isRFPViewable = (iffs: ClientFeatureFlags) => (rfp: Rfp) => isFFEnabled(rfpToFF(rfp))(iffs);

// An RFP is archived if it has been awarded or if it is more than 2 weeks past the bid due date. The back-end sets the biddingState to BidAwarded once two weeks have passed.
export const isRFPArchived = (rfp: Rfp) => rfp.biddingState._tag === "BidAwarded";

export const allowQuestionSubmissions = (rfp: Rfp) => rfp.allowQuestions && !isRFPArchived(rfp);

export const showQuestionsAndAnswersSection = (rfp: Rfp, faqs: RfpRelatedContent["faqs"]) => RA.isNonEmpty(faqs) || allowQuestionSubmissions(rfp);

export type RfpBidSubmissionState = {
  isBiddingOpen: boolean;
  acceptsBidsOnSite: boolean;
  isBidInProgress: boolean;
  isBidSubmitted: boolean;
  isTemplateOutdated: O.Option<Joda.LocalDateTime>;
  reload: () => void;
};

export const rfpBidSubmissionState = (config: BLConfigWithLog, isBidInProgress: boolean, lastSaved: O.Option<Joda.LocalDateTime>, reload: () => void) => (
  rfp: Rfp,
  bidSubmissionTemplate: O.Option<WithStatusU<WithModInfo<BidSubmissionTemplateData>>>,
  bidSubmission: O.Option<WithStatusU<WithModInfo<BidSubmissionData>>>,
): RfpBidSubmissionState => ({
  isBiddingOpen: (() => {
    switch (rfp.biddingState._tag) {
      case "Unscheduled":
      case "BiddingOpen": return true;
      case "BiddingClosed":
      case "BidAwarded": return false;
      default: return config.exhaustive(rfp.biddingState);
    }
  })(),
  acceptsBidsOnSite: rfp.acceptBidsOnSite && O.isSome(bidSubmissionTemplate),
  isBidInProgress,
  isBidSubmitted: O.isSome(bidSubmission),
  isTemplateOutdated: pipe(bidSubmissionTemplate,
    O.chain(t => t.data.record.modified),
    O.bindTo("templateModified"),
    O.bind("saved", () => lastSaved),
    O.filter(({ templateModified, saved }) => templateModified.isAfter(saved)),
    O.map(({ saved }) => saved)
  ),
  reload,
});

export const rfpBidSubmissionBtnText = (state: RfpBidSubmissionState): O.Option<string> => {
  if (state.acceptsBidsOnSite) {
    if (!state.isBiddingOpen) {
      return state.isBidSubmitted ? O.some("Review bid") : O.none;
    } else {
      if (state.isBidSubmitted) {
        return O.some("Review/edit bid");
      } else if (state.isBidInProgress) {
        return O.some("Continue bid submission");
      } else {
        return O.some("Start bid submission");
      }
    }
  } else {
    return O.none;
  }
};

// Whenever possible, DO NOT USE THIS. Use the `biddingState` field on an `Rfp` instead.
// If you change this, please sync with the backend team to update the equivalent backend version.
export const unsafeComputeBiddingStateIgnoreTime = (bidAwarded: Rfp["bidAwarded"], bidsDue: Rfp["bidsDue"]): bs.BiddingStateU => {
  if (bidAwarded) {
    return bs.bidAwarded;
  } else {
    return pipe(
      bidsDue,
      O.fold(
        (): bs.BiddingStateU => bs.unscheduled,
        E.fold(
          d => {
            const today = localDateNow();
            const cutoffDate = today.minusDays(14);
            if (d.date.isBefore(cutoffDate)) {
              return bs.bidAwarded;
            } else if ((d.date.isAfter(cutoffDate) || d.date.equals(cutoffDate)) && d.date.isBefore(today)) {
              return bs.biddingClosed;
            } else {
              return bs.biddingOpen;
            }
          },
          () => bs.biddingOpen,
        ),
      ),
    );
  }
};

export const removeTagsAndRelatedContent = (
  _: WithStatusU<TaggedContent<GenRfpWithRelatedContent<RfpRelatedContent>>>
): WithStatusU<Rfp> => ({
  ..._,
  data: {
    ..._.data,
    record: _.data.record.data.rfp,
  },
});
