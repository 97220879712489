import { type PropsWithChildren, type ReactNode, useCallback, useEffect, useState } from "react";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";

import { formatS3CdnUrl } from "@scripts/bondlink";
import { ET, LocalDateC, LocalDateTimeFromIsoStringC } from "@scripts/codecs/localDate";
import { constVoid, flow, pipe } from "@scripts/fp-ts";
import { warning } from "@scripts/generated/domaintables/alertTypes";
import { disabledC, enabledRequiredC } from "@scripts/generated/domaintables/bidSubmissionTemplateDetailsState";
import type { BidSubmissionTemplateQuestion } from "@scripts/generated/models/bidSubmissionTemplates";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import { s3PrivateAcl } from "@scripts/generated/models/s3Upload";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Anchor } from "@scripts/react/components/Anchor";
import { ButtonPrimary, ButtonsContainer, ButtonSecondary, ButtonSubmit } from "@scripts/react/components/Button";
import { falseOrEmpty, mapOrEmpty } from "@scripts/react/components/Empty";
import { CancelButton } from "@scripts/react/components/form/ActionButtons";
import { CustomIndicator } from "@scripts/react/components/form/CustomIndicator";
import { FieldGroupFormSection, FormSection } from "@scripts/react/components/form/FieldGroup";
import { Form } from "@scripts/react/components/form/Form";
import { getFileErr, mediaOptionToMediaIso, UploaderBaseButtonLink } from "@scripts/react/components/form/S3Uploader";
import { CurrencyInput, Input, PercentInput, TextArea } from "@scripts/react/components/form/TextInput";
import { Grid, GridCol, type GridCols } from "@scripts/react/components/layout/Grid";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import { bigNumericFormatC, booleanC, optionC, stringC } from "@scripts/react/form/codecs";
import { formModifiedLens, getValue, updateAndSetState } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import { humanDateFull, humanDateFullAtTime, localDateNow, localDateTimeNowET, toSystemZone } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { isDrafted } from "@scripts/syntax/threadThrough";
import { allowedDocMimeTypes } from "@scripts/uploader/mimeTypes";

import clockIcon from "@svgs/clock.svg";

import { Alert } from "../../Alert";
import { Tooltip } from "../../Tooltip";
import { attachedAnswerL, attachedDocumentL, type BidSubmissionFormContentBaseProps, type BidSubmissionFormProps, type BidSubmissionModalDataProps, bidSubmissionPostL, clearBidSubmissionProgress, saveBidSubmissionProgress } from "./BidSubmissionSyntax";
const BidsDueHeader = (props: PropsWithChildren) => <h5 className="teal-700"><Svg src={clockIcon} /> {props.children}</h5>;

const responsiveColSize: ReadonlyArray<GridCols> = [".c-24", ".c-sm-12"];

const BidSubmissionFormContentBase = (props: BidSubmissionFormContentBaseProps) => {
  const isBiddingOpen = props.bidSubmissionState.isBiddingOpen;

  return <div className="bid-submission-form">
    {pipe(props.bidSubmissionState.isTemplateOutdated,
      mapOrEmpty(lastSaved => <div {...klass("mb-15")}>
        <Alert type={warning} icon={false} pill={false}>
          This bid submission form has been updated since you last saved progress on {humanDateFullAtTime(toSystemZone(ET)(lastSaved))}, which means that some questions and/or document requests may have been changed. Make sure to review any of your previous responses to ensure accuracy in your bid submission.
        </Alert>
      </div>)
    )}
    <FormSection>
      <h3>{props.rfpName}</h3>
      {mapOrEmpty((intro: string) => <p className="mb-05">{intro}</p>)(props.introductionText)}
    </FormSection>
    <FormSection>
      {pipe(
        props.bidsDue,
        O.fold(
          () => "The due date for bids has not yet been scheduled",
          E.fold(
            (dwt) => `${!isBiddingOpen ? "Bidding closed on" : "Bids due on"} ${dateWithOptionalTimeToString(humanDateFull)(dwt)}`,
            ongoing => `Bids are accepted on an ${ongoing.name} basis`
          ),
        ),
        (bidsDueText) => <BidsDueHeader>{bidsDueText}</BidsDueHeader>
      )}
      {pipe(
        props.bidSubmission,
        mapOrEmpty((sd) => {
          const lastSubmittedDate = O.getOrElse(() => sd.data.record.created)(sd.data.record.modified);

          return <p>You submitted your bid on {humanDateFullAtTime(toSystemZone(ET)(lastSubmittedDate))}. {!isBiddingOpen
            ? "Since the submission deadline has passed, this bid can no longer be edited"
            : "You can edit and re-submit this bid as long as it is before the submission deadline"}.</p>;
        })
      )}
    </FormSection>
    <FieldGroupFormSection
      title={O.some("Contact Information")}
      subtitle={O.none}
      tip={O.none}
    >
      <Grid attrs={O.some(".grid-sx-2")} klasses={O.none}>
        <GridCol cols={responsiveColSize} klasses={O.none}>
          <Input
            type={"text"}
            codec={stringC}
            labelOrAriaLabel={E.left("First Name")}
            placeholder={O.none}
            state={props.state}
            setState={props.setState}
            lens={bidSubmissionPostL("firstName")}
            disabled={!isBiddingOpen}
          />
        </GridCol>
        <GridCol cols={responsiveColSize} klasses={O.none}>
          <Input
            type={"text"}
            codec={stringC}
            labelOrAriaLabel={E.left("Last Name")}
            placeholder={O.none}
            state={props.state}
            setState={props.setState}
            lens={bidSubmissionPostL("lastName")}
            disabled={!isBiddingOpen}
          />
        </GridCol>
        <GridCol cols={responsiveColSize} klasses={O.none}>
          <Input
            type={"text"}
            codec={stringC}
            labelOrAriaLabel={E.left("Organization")}
            placeholder={O.none}
            state={props.state}
            setState={props.setState}
            lens={bidSubmissionPostL("organization")}
            disabled={!isBiddingOpen}
          />
        </GridCol>
        <GridCol cols={responsiveColSize} klasses={O.none}>
          <Input
            type={"text"}
            codec={stringC}
            labelOrAriaLabel={E.left("Email Address")}
            placeholder={O.none}
            state={props.state}
            setState={props.setState}
            lens={bidSubmissionPostL("email")}
            disabled={!isBiddingOpen}
          />
        </GridCol>
      </Grid>
    </FieldGroupFormSection>
    {props.children}
  </div>;
};

const BidSubmissionQuestion = (props: Pick<BidSubmissionFormProps, "state" | "setState"> & {
  question: WithStatusU<BidSubmissionTemplateQuestion>;
  isBiddingOpen: boolean;
  index: number;
}) => {

  const attachedAnswerLens = attachedAnswerL("yesOrNo")(props.question.data.id, props.index);

  return <GridCol key={props.question.data.id} cols={[".c-24"]} klasses={O.none}>
    <div className="bid-submission-form-question">
      {props.question.data.record.yesOrNo
        ? <CustomIndicator
          state={props.state}
          setState={props.setState}
          lens={attachedAnswerLens}
          codec={optionC(booleanC)}
          name={props.question.data.id.toString()}
          type="radio"
          options={[
            { label: O.some("Yes"), value: true },
            { label: O.some("No"), value: false },
          ]}
          unselectedValue={O.none}
          analyticsScope={O.none}
          ariaUId={O.none}
          disabled={!props.isBiddingOpen}
          required={props.question.data.record.required}
          labelOrAriaLabel={E.left(props.question.data.record.questionText)}
          isClearable={props.isBiddingOpen}
        />
        : <TextArea
          codec={optionC(stringC)}
          labelOrAriaLabel={E.left(props.question.data.record.questionText)}
          placeholder={O.none}
          state={props.state}
          setState={props.setState}
          lens={attachedAnswerL("text")(props.question.data.id, props.index)}
          disabled={!props.isBiddingOpen}
          requiredOverride={props.question.data.record.required}
        />
      }
    </div>
  </GridCol>;
};

const DisabledIfDrafted = (props: {
  rfp: WithStatusU<Rfp>;
  action: "submitted" | "saved";
  children: (disabled: boolean) => ReactNode;
}) => {
  const drafted = isDrafted(props.rfp);
  return drafted
    ? <Tooltip
      delay="default"
      description={{
        type: "DescriptionContentItalicized",
        text: `Bids cannot be ${props.action} for RFPs with outstanding changes. Go to change approval to review changes to this RFP.`,
      }}
    >{props.children(drafted)}</Tooltip>
    : props.children(drafted);
};

export const BidSubmissionForm = (props: BidSubmissionFormProps & BidSubmissionModalDataProps) => {

  const config = useConfig();

  const bidSubmission = props.bidSubmission;
  const hasBeenSubmitted = O.isSome(bidSubmission);
  const isBiddingOpen = props.bidSubmissionState.isBiddingOpen;

  const [localSaveState, setLocalSaveState] = useState(false);

  useEffect(() => {
    setLocalSaveState(false);
  }, [props.state.data]);


  const saveProgress = useCallback(() => {
    setLocalSaveState(true);
    saveBidSubmissionProgress(props.rfp.data.id, {
      ...props.state.data,
      lastSaved: LocalDateTimeFromIsoStringC.encode(localDateTimeNowET()),
      expirationDate: pipe(
        props.rfp.data.record.bidsDue,
        O.chain(O.getLeft),
        O.fold(
          () => localDateNow().plusDays(90),
          d => d.date.plusDays(30),
        ),
        LocalDateC.encode,
      ),
    });
    props.setState(formModifiedLens().set(false)(props.state));
    pipe(props.subscription, O.map(({ isSubscribed, onSubscribe }) => !isSubscribed && onSubscribe()));
    props.bidSubmissionState.reload();
  }, [props]);

  return <Form
    url={V2Router.investorPortalRfpsControllerSubmitBid()}
    state={props.state}
    setState={props.setState}
    // eslint-disable-next-line react/jsx-no-bind
    onSuccess={(s, d) => {
      clearBidSubmissionProgress(props.rfp.data.id);
      props.bidSubmissionState.reload();
      props.onSuccess(s, d);
    }}
    onFailure={O.none}
    headers={O.none}
  >
    {pipe(
      props.bidSubmissionTemplate,
      O.fold(
        () => <BidSubmissionFormContentBase
          state={props.state}
          setState={props.setState}
          rfpName={props.rfp.data.record.name}
          introductionText={O.none}
          bidSubmission={bidSubmission}
          bidsDue={props.rfp.data.record.bidsDue}
          bidSubmissionState={props.bidSubmissionState}
        />,
        _ => {
          const bidSubmissionTemplateRecord = _.data.record.data;

          return <BidSubmissionFormContentBase
            state={props.state}
            setState={props.setState}
            rfpName={props.rfp.data.record.name}
            introductionText={bidSubmissionTemplateRecord.bidSubmissionTemplate.introductionText}
            bidSubmission={bidSubmission}
            bidsDue={props.rfp.data.record.bidsDue}
            bidSubmissionState={props.bidSubmissionState}
          >
            {!(disabledC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.interestRate)
              && disabledC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.fees)
              && disabledC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.additionalComments))
              && <FieldGroupFormSection
                title={O.some("Bid Details")}
                subtitle={O.none}
                tip={O.none}
              >
                <Grid attrs={O.some(".grid-sx-2")} klasses={O.none}>
                  {falseOrEmpty(
                    <GridCol cols={responsiveColSize} klasses={O.none}>
                      <PercentInput
                        codec={optionC(bigNumericFormatC)}
                        labelOrAriaLabel={E.left("Interest Rate")}
                        state={props.state}
                        setState={props.setState}
                        lens={bidSubmissionPostL("interestRate")}
                        requiredOverride={enabledRequiredC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.interestRate)}
                        disabled={!isBiddingOpen}
                        numberOfDecimalPlaces={2}
                      />
                    </GridCol>
                  )(disabledC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.interestRate))}
                  {falseOrEmpty(
                    <GridCol cols={responsiveColSize} klasses={O.none}>
                      <CurrencyInput
                        codec={optionC(bigNumericFormatC)}
                        labelOrAriaLabel={E.left("Bank/Counsel Fees")}
                        state={props.state}
                        setState={props.setState}
                        lens={bidSubmissionPostL("fees")}
                        requiredOverride={enabledRequiredC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.fees)}
                        disabled={!isBiddingOpen}
                        numberOfDecimalPlaces={2}
                      />
                    </GridCol>
                  )(disabledC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.fees))}
                  {falseOrEmpty(
                    <GridCol cols={[".c-24"]} klasses={O.none}>
                      <TextArea
                        codec={optionC(stringC)}
                        labelOrAriaLabel={E.left("Additional Comments")}
                        placeholder={O.none}
                        state={props.state}
                        setState={props.setState}
                        lens={bidSubmissionPostL("additionalComments")}
                        requiredOverride={enabledRequiredC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.additionalComments)}
                        disabled={!isBiddingOpen}
                      />
                    </GridCol>
                  )(disabledC.is(bidSubmissionTemplateRecord.bidSubmissionTemplate.additionalComments))}
                </Grid>
              </FieldGroupFormSection>}
            {bidSubmissionTemplateRecord.questions.length > 0 && <FieldGroupFormSection
              title={O.some("Questions")}
              subtitle={O.none}
              tip={O.none}
            >
              <Grid attrs={O.some(".grid-sx-2")} klasses={O.none}>
                {bidSubmissionTemplateRecord.questions.map((question, ii) =>
                  <BidSubmissionQuestion
                    key={question.data.id}
                    state={props.state}
                    setState={props.setState}
                    question={question}
                    isBiddingOpen={isBiddingOpen}
                    index={ii}
                  />
                )}
              </Grid>
            </FieldGroupFormSection>}
            {bidSubmissionTemplateRecord.documents.length > 0 && <FieldGroupFormSection
              title={O.some("Document Requests")}
              subtitle={O.none}
              tip={O.none}
            >
              <Grid attrs={O.some(".grid-sx-2")} klasses={O.none}>
                {bidSubmissionTemplateRecord.documents.map((document, index) => {
                  const documentLens = attachedDocumentL(document.data.id, index);
                  const media = getValue(props.state, documentLens);
                  const fileErr = getFileErr(props.state, documentLens.composeIso(mediaOptionToMediaIso), config);

                  const templateDocument = document.data.record.bidSubmissionTemplateDocument;

                  return <GridCol key={document.data.id} cols={[".c-24"]} klasses={O.some("bid-submission-form-document")}>
                    <UploaderBaseButtonLink
                      label={O.some({ label: templateDocument.name, required: templateDocument.required })}
                      labelActionEl={pipe(
                        document.data.record.documentTemplate,
                        O.map(docTemplate =>
                          <div key={`template-download-${docTemplate.data.id}`}>
                            <Anchor
                              route={{
                                title: "Download Template",
                                route: { method: "GET", url: formatS3CdnUrl(config)(docTemplate.data.record.uploadResponse.uri) },
                              }}
                              target="_blank"
                              externalLinkLocation="none"
                            />
                          </div>
                        )
                      )}
                      errMsgOnLabel
                      acl={s3PrivateAcl}
                      allowedMimeTypes={allowedDocMimeTypes}
                      bucket={config.s3.issuerBidSubmissionsBucket}
                      clientId={props.issuer.clientId}
                      fileErr={fileErr}
                      media={media}
                      onFileChanged={flow(O.some, updateAndSetState(props.setState, documentLens))}
                      isEditable={isBiddingOpen}
                      // eslint-disable-next-line react/jsx-no-bind
                      onDelete={() => { updateAndSetState(props.setState, documentLens)(O.none); }}
                      // eslint-disable-next-line react/jsx-no-bind
                      openDocument={(__c, mediaUri, target) => globalThis.open(V2Router.investorPortalRfpsControllerDownloadBidSubmissionDocument({ mediaUri }).url, target)}
                    />
                  </GridCol>;
                })}
              </Grid>
            </FieldGroupFormSection>}
          </BidSubmissionFormContentBase>;
        }
      )
    )}
    <ButtonsContainer klasses={O.fromPredicate(() => !isBiddingOpen)("pt-1")} divider>
      {isBiddingOpen
        ? <>
          {!hasBeenSubmitted && <p className="mt-05 mb-0">If you are not ready to submit your bid, you can press the “Save Progress” button below. Note that your bid will not be sent until you fully complete this form and press the “Submit” button.</p>}
          <div className="d-flex">
            <DisabledIfDrafted rfp={props.rfp} action="submitted">
              {disabled => <ButtonSubmit
                disabled={disabled || !(props.bidSubmissionState.isBidInProgress || props.state.modified)}
                loading={props.state.loading}
                loadingText={hasBeenSubmitted ? "Re-submitting" : "Submitting"}
                onClick={constVoid}
                text={hasBeenSubmitted ? "Re-submit" : "Submit"}
                variant="primary"
                className="mt-0 mr-1"
              />}
            </DisabledIfDrafted>
            {!hasBeenSubmitted
              && <DisabledIfDrafted rfp={props.rfp} action="saved">
                {disabled => <ButtonSecondary
                  className="mt-0"
                  onClick={saveProgress}
                  disabled={disabled || !props.state.modified || localSaveState}
                >
                  {localSaveState ? "Progress Saved" : "Save Progress"}
                </ButtonSecondary>}
              </DisabledIfDrafted>
            }
          </div>
          <CancelButton dismissAction={props.dismissAction} />
        </>
        : <ButtonPrimary onClick={props.dismissAction}>Close</ButtonPrimary>
      }
    </ButtonsContainer>
  </Form >;
};
