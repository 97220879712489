import type { Borders, Cell, Workbook, Worksheet } from "exceljs";

import { downloadBlob } from "../csvExporter";
import { colorScaleMax, colorScaleMin, darkGrayFill, defaultFont, type ExcelColor, lime700, white } from "./syntax";

export const downloadSpreadsheet = (workbook: Workbook, title: string) => {
  workbook.xlsx.writeBuffer().then(buffer => {
    downloadBlob(title, new Blob([buffer]));
  });
};

// from https://stackoverflow.com/a/75068548
const uppercaseAlphas = Array.from({ length: 26 }, (_0, i) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  String.fromCodePoint(i + "A".codePointAt(0)!),
);

const divmodExcel = (n: number): [number, number] => {
  const a = Math.floor(n / 26);
  const b = n % 26;

  return b === 0 ? [a - 1, b + 26] : [a, b];
};

const toExcelCol = (n: number) => {
  const chars = [];

  let d;
  while (n > 0) {
    [n, d] = divmodExcel(n);
    chars.unshift(uppercaseAlphas[d - 1]);
  }
  return chars.join("");
};

/**
 * @param column 0-based column index
 * @param row 0-based row index
 * @returns Excel cell address (e.g. C20, AA10)
 */
export const coordsToCellAddress = (column: number, row: number) => {
  return `${toExcelCol(column + 1)}${row + 1}`;
};

/**
 * @param worksheet Worksheet containing cells
 * @param cellFn Function to apply to all cells in range (inclusive)
 * @param columnStart 0-based column index
 * @param columnEnd 0-based column index (inclusive)
 * @param rowStart 0-based row index
 * @param rowEnd 0-based row index (inclusive)
 */
const applyToRange = (
  worksheet: Worksheet,
  cellFn: (cell: Cell) => void,
  columnStart: number,
  columnEnd: number,
  rowStart: number,
  rowEnd: number
) => {
  for (let column = columnStart; column <= columnEnd; column++) {
    for (let row = rowStart; row <= rowEnd; row++) {
      const cell = worksheet.getCell(coordsToCellAddress(column, row));
      cellFn(cell);
    }
  }
};

/**
 * @param worksheet Worksheet containing cells
 * @param column  0-based column index
 * @param rowStart  0-based row index
 * @param rowEnd  0-based row index (inclusive)
 */
export const applyColorScale = (
  worksheet: Worksheet,
  column: number,
  rowStart: number,
  rowEnd: number
) => {
  // Apply to totals row in addition to data rows
  applyToRange(worksheet, alignLeft, column, column, rowStart, rowEnd + 1);

  worksheet.addConditionalFormatting({
    ref: `${coordsToCellAddress(column, rowStart)}:${coordsToCellAddress(column, rowEnd)}`,
    rules: [
      {
        type: "colorScale",
        color: [
          colorScaleMin,
          colorScaleMax,
        ],
        cfvo: [
          {
            type: "num",
            value: 0.1,
          }, {
            type: "max",
          },
        ],
        priority: 0,
      },
    ],
  });
};

/**
 * @param worksheet Worksheet containing cells
 * @param column  0-based column index
 * @param rowStart  0-based row index
 * @param rowEnd  0-based row index (inclusive)
 */
export const applyDataBar = (
  worksheet: Worksheet,
  column: number,
  rowStart: number,
  rowEnd: number
) => {
  applyToRange(worksheet, alignLeft, column, column, rowStart, rowEnd);

  for (let rowIndex = rowStart; rowIndex <= rowEnd; rowIndex++) {
    const cellAddress = coordsToCellAddress(column, rowIndex);

    applyBold(worksheet, cellAddress);
    applyTextColor(worksheet, cellAddress, white);
  }

  worksheet.addConditionalFormatting({
    ref: `${coordsToCellAddress(column, rowStart)}:${coordsToCellAddress(column, rowEnd)}`,
    rules: [
      {
        type: "dataBar",
        // The types for exceljs are handwritten and not quite correct - BS
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        color: lime700,
        cfvo: [
          {
            type: "num",
            value: 0,
          }, {
            type: "num",
            value: 4,
          },
        ],
        gradient: false,
        priority: 0,
      },
    ],
  });
};

export const applyBold = (worksheet: Worksheet, cellAddress: string) => {
  const cell = worksheet.getCell(cellAddress);

  cell.style = {
    ...cell.style,
    font: {
      ...cell.style.font,
      bold: true,
    },
  };
};

export function applyDarkGrayFill(worksheet: Worksheet, cellAddress: string): void {
  const cell = worksheet.getCell(cellAddress);

  cell.style = {
    ...cell.style,
    fill: darkGrayFill,
  };
}

export const applyFont = (worksheet: Worksheet) => {
  worksheet.getRows(1, 1000)?.map(row => {
    row.eachCell({ includeEmpty: true }, cell => {
      cell.style = {
        ...cell.style,
        font: {
          ...cell.style.font,
          ...defaultFont,
        },
      };
    });
  });
};

export const applyBorder = (worksheet: Worksheet, cellAddress: string, border: Partial<Borders>) => {
  const cell = worksheet.getCell(cellAddress);

  cell.border = {
    ...cell.border,
    ...border,
  };
};

const applyTextColor = (worksheet: Worksheet, cellAddress: string, color: ExcelColor) => {
  const cell = worksheet.getCell(cellAddress);

  cell.style = {
    ...cell.style,
    font: {
      ...cell.style.font,
      color,
    },
  };
};

const alignLeft = (cell: Cell) => {
  cell.style = {
    ...cell.style,
    alignment: {
      ...cell.style.alignment,
      horizontal: "left",
    },
  };
};

export const applyTextWrap = (worksheet: Worksheet, cellAddress: string) => {
  const cell = worksheet.getCell(cellAddress);

  cell.style = {
    ...cell.style,
    alignment: {
      ...cell.alignment,
      wrapText: true,
    },
  };
};
