import * as O from "fp-ts/lib/Option";

import { FormModalDataLoaderWithState } from "@scripts/react/components/form/FormModal";
import { useConfig } from "@scripts/react/context/Config";
import { type CloseModalFn, type ModalOpen } from "@scripts/react/util/useModal";

import rfpIcon from "@svgs/rfp.svg";

import { BidSubmissionForm } from "./BidSubmissionForm";
import { type BidSubmissionModalDataProps, initializeRfpBidSubmissionState } from "./BidSubmissionSyntax";

export type BidSubmissionModalProps = {
  modalOpen: ModalOpen;
  dismissAction: CloseModalFn;
  successAction: () => void;
};

export const BidSubmissionModal = (props: BidSubmissionModalProps & BidSubmissionModalDataProps) => {
  const config = useConfig();

  return (
    <FormModalDataLoaderWithState
      customFormProps={{
        user: props.user,
        rfp: props.rfp,
        bidSubmission: props.bidSubmission,
        setBidSubmission: props.setBidSubmission,
        bidSubmissionTemplate: props.bidSubmissionTemplate,
        issuer: props.issuer,
        bidSubmissionState: props.bidSubmissionState,
        subscription: props.subscription,
      }}
      form={BidSubmissionForm}
      formHeader={O.none}
      formProps={{
        deleteButton: O.none,
        dismissAction: props.dismissAction,
        onSuccess: (d) => {
          props.setBidSubmission(d.data);
          props.successAction();
        },
      }}
      initialState={initializeRfpBidSubmissionState(config, props.user, props.rfp.data.id, props.bidSubmission, props.bidSubmissionTemplate)}
      modalProps={{
        dataMeta: {
          customDisplayName: O.none,
          svg: rfpIcon,
          type: "Bid Submission",
        },
        size: "modal-lg",
        open: props.modalOpen,
        action: "none",
      }}
    />
  );
};
