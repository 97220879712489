import { useEffect, useState } from "react";
import { useStableO } from "fp-ts-react-stable-hooks";

import { LocalDateTimeFromIsoStringC } from "@scripts/codecs/localDate";
import { constVoid, E, O, pipe } from "@scripts/fp-ts";
import type { BiddingStateU } from "@scripts/generated/domaintables/biddingStates";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import { Badge, type BadgeProps } from "@scripts/react/components/Badge";
import type { Joda } from "@scripts/syntax/date/joda";
import { type RfpBidSubmissionState, rfpBidSubmissionState } from "@scripts/syntax/rfp";

import type { ContentItemCardBadgeProps } from "../components/card/ContentItemCard";
import { mapOrEmpty } from "../components/Empty";
import { getBidSubmissionProgress } from "../components/form/submit-bid/BidSubmissionSyntax";
import { useConfig } from "../context/Config";
import { useReload } from "../util/useReload";

const badgeDetails: Record<Rfp["biddingState"]["_tag"], { text: string, color: "gray-600" | "yellow-700" | "green-700" }> = {
  Unscheduled: { text: "Unscheduled", color: "gray-600" },
  BiddingOpen: { text: "Open for Bids", color: "green-700" },
  BiddingClosed: { text: "Bidding Closed", color: "yellow-700" },
  BidAwarded: { text: "Archived", color: "gray-600" },
};

export const rfpBadgeProps = (biddingState: BiddingStateU, variant?: BadgeProps["variant"]): BadgeProps => ({
  icon: O.none,
  text: badgeDetails[biddingState._tag].text,
  color: badgeDetails[biddingState._tag].color,
  variant: variant ?? "tall",
});

export const RfpBadge = (props: { biddingState: BiddingStateU, variant?: BadgeProps["variant"] }) =>
  <Badge {...rfpBadgeProps(props.biddingState, props.variant)} />;

export const rfpBidSubmissionBadgeProps = (state: RfpBidSubmissionState, variant?: BadgeProps["variant"]): O.Option<ContentItemCardBadgeProps> => pipe(
  O.fromPredicate(() => state.acceptsBidsOnSite)(constVoid()),
  O.chain((): O.Option<ContentItemCardBadgeProps> => {
    const base: Pick<ContentItemCardBadgeProps, "variant" | "wrapperKlass"> = { variant: variant ?? "tall", wrapperKlass: "flex-no-shrink" };
    const bidSubmitted: ContentItemCardBadgeProps = { ...base, text: "Bid Submitted", icon: O.none, color: "magenta-700" };

    if (state.isBiddingOpen) {
      if (state.isBidSubmitted) {
        return O.some(bidSubmitted);
      } else if (state.isBidInProgress) {
        return O.some({ ...base, text: "Bid in progress", icon: O.none, backgroundKlass: "magenta-100-bg magenta-700" });
      } else {
        return O.none;
      }
    } else {
      return state.isBidSubmitted ? O.some(bidSubmitted) : O.none;
    }
  }),
);

export const useRfpBidSubmissionState = (
  rfpId: number,
  ...args: Parameters<ReturnType<typeof rfpBidSubmissionState>>
): RfpBidSubmissionState => {
  const config = useConfig();
  const reload = useReload();

  // Initialization of `isBidInProgress` is deferred because `localStorage` doesn't exist on the server.
  // Note: we can't precompute `isBidInProgress`, even on the client, because the precomputed value may differ
  // between server and client, resulting in hydration errors.
  const [isBidInProgress, setIsBidInProgress] = useState(false);
  const [lastSaved, setLastSaved] = useStableO<Joda.LocalDateTime>(O.none);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      pipe(
        getBidSubmissionProgress(rfpId),
        E.fold(
          () => {
            setIsBidInProgress(false);
            setLastSaved(O.none);
          },
          p => {
            setIsBidInProgress(true);
            setLastSaved(O.fromEither(LocalDateTimeFromIsoStringC.decode(p.lastSaved)));
          }
        )
      );
    }
  }, [rfpId, setLastSaved, reload.ts]);

  return { ...rfpBidSubmissionState(config, isBidInProgress, lastSaved, reload.fn)(...args) };
};

export const RfpBidSubmissionBadge = (props: { state: RfpBidSubmissionState, variant?: BadgeProps["variant"] }) =>
  pipe(rfpBidSubmissionBadgeProps(props.state, props.variant), mapOrEmpty(p => <Badge {...p} />));

export const RfpBadges = (props: {
  rfp: Rfp;
  bidSubmissionState: RfpBidSubmissionState;
}) => {
  return <div className="d-flex flex-wrap">
    <span className="mb-05 mr-1"><RfpBadge biddingState={props.rfp.biddingState} /></span>
    <span className="mb-05"><RfpBidSubmissionBadge state={props.bidSubmissionState} /></span>
  </div>;
};
