import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as t from "io-ts";
import V from "voca";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { type PageU, SortableCU, type SortableU } from "@scripts/generated/domaintables/pages";
import type { Cusip6SortColumnU, Cusip9SortColumnU } from "@scripts/generated/domaintables/sortColumns";
import { ASC, DESC } from "@scripts/generated/domaintables/sortDir";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { type PageSort, pageSortC, type PageSorts } from "@scripts/generated/models/pageSorts";
import type { SelectOption } from "@scripts/react/components/form/Select";

import { getPage } from "./pageConfig";

const pageSortTupleC = t.tuple([SortableCU, pageSortC]);

const isPageSorts = (x: PageSorts | ReadonlyArray<PageConfig<PageU>>): x is PageSorts => pipe(
  RA.head<unknown>(x),
  O.fold(() => true, pageSortTupleC.is),
);

export const getPageSort = (page: SortableU) => (pages: PageSorts | ReadonlyArray<PageConfig<PageU>>): PageSort => pipe(
  isPageSorts(pages)
    ? pipe(pages, RA.findFirst(p => p[0].id === page.id), O.map(_ => _[1]))
    : pipe(getPage(page)(pages), O.chain(_ => _.sort)),
  O.getOrElse((): PageSort => ({ column: page.defaultSortCol, direction: page.defaultSortDir }))
);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unnecessary-type-parameters
export const makeSortOptionValue = <SB extends string>(sort: PageSort): SB => makeSortableColumnName(sort.column.columnName, sort.direction.dir) as SB;

export const makeSortableColumnName = (column: string, direction: string) => `${V.camelCase(column)}-${direction}`;

const humanName = (config: BLConfigWithLog) => (col: Cusip6SortColumnU | Cusip9SortColumnU): string => {
  switch (col._tag) {
    case "Cusip6Name": return "Name";
    case "Cusip6Cusip6": return "CUSIP-6";
    case "Cusip9BondName": return "Name";
    case "Cusip9Cusip": return "CUSIP-9";
    case "Cusip9IssueDate": return "Issue Date";
    case "Cusip9DatedDate": return "Dated Date";
    case "Cusip9MaturityDate": return "Maturity Date";
    default: return config.exhaustive(col);
  }
};

const makeTwoSortOptions = (config: BLConfigWithLog) => (column: Cusip6SortColumnU | Cusip9SortColumnU): ReadonlyArray<SelectOption<PageSort>> => {
  const name = humanName(config)(column);
  return [
    {
      label: `${name} ↑`,
      value: { column, direction: ASC },
    },
    {
      label: `${name} ↓`,
      value: { column, direction: DESC },
    },
  ];
};

export const makeSortOptions = (config: BLConfigWithLog) => (columns: ReadonlyArray<Cusip6SortColumnU | Cusip9SortColumnU>) =>
  columns.reduce((acc: ReadonlyArray<SelectOption<PageSort>>, c) => [...acc, ...(makeTwoSortOptions(config)(c))], []);
