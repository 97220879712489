import type * as t from "io-ts";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import type { DocumentDownloadRoute } from "@scripts/react/components/ratings/RatingBlock";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import { type IssuerSitesRouteMeta, program, type ProgramPageData, type ProgramPageDataC } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { DirectSitesPageLayoutJumpLinks } from "../../components/DirectSitesPageLayout";
import { HeaderWithoutSubscription } from "../../components/offering-pages/Header";
import { CusipsTable, makeEventsDataO, makeNewsDataO, makeProjectsDataO, RelatedContentSection, SharedRelatedContent } from "../../components/offering-pages/RelatedContent";
import { BondsSection, getNonEmptyItemsRecordO, RfpsSection } from "../../components/related-content/DealSection";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { ProgramOverviewSection } from "./program/Overview";
import { RatingsSection } from "./program/RatingsSection";

const setupSidebarLinkSections = (
  data: ProgramPageData,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.program.all(data.data.record));

const ProgramSidebarLinks = (props: {
  jumplinks: ReadonlyArray<JumpLink>;
  programRouteMeta: IssuerSitesRouteMeta<ProgramPageData, t.OutputOf<ProgramPageDataC>, "bond-program">;
  program: ProgramPageData;
}) => pipe(
  RNEA.fromReadonlyArray(props.jumplinks),
  mapOrEmpty(jumplinks =>
    <SidebarLinksSites
      headerLinkAnchorContent={props.program.data.record.program.name}
      jumpLinks={jumplinks}
      routeMeta={props.programRouteMeta}
    />
  )
);

export const ProgramPage = (props: { programWithRelatedContent: ProgramPageData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const programRouteMeta = program({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    programId: props.programWithRelatedContent.data.id,
  });

  const documentsDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.externalLinks);
  const bondsDataO = pipe(
    props.programWithRelatedContent.data.record.relatedContent.offerings,
    RA.partition(_ => _.data.data.record.data.offering.isArchived),
    (_) => getNonEmptyItemsRecordO({ active: _.left, archived: _.right })
  );
  const rfpsDataO = pipe(
    props.programWithRelatedContent.data.record.relatedContent.rfps,
    RA.partition(_ => _.data.data.record.data.rfp.biddingState._tag === "BidAwarded"),
    (_) => getNonEmptyItemsRecordO({ active: _.left, archived: _.right })
  );
  const cusip9sDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.cusip9s);
  const projectsDataO = makeProjectsDataO(iffs, props.programWithRelatedContent.data.record.relatedContent.projects);
  const newsDataO = makeNewsDataO(iffs, props.programWithRelatedContent.data.record.relatedContent.news);
  const eventsDataO = makeEventsDataO(iffs, props.programWithRelatedContent.data.record.relatedContent.events);
  const ratingsDataO = RNEA.fromReadonlyArray(props.programWithRelatedContent.data.record.relatedContent.ratings);

  const sidebarLinks = setupSidebarLinkSections(props.programWithRelatedContent, iffs, pages);
  const overviewLink = jl.program.overview(props.programWithRelatedContent.data.record);
  const relatedContentLink = jl.program.relatedContent(props.programWithRelatedContent.data.record.relatedContent);

  const documentDownloadRoute: DocumentDownloadRoute = (id) => SR.issuersitesReportsControllerDownloadRedirect({ mediaId: id, issuerId: issuer.id, issuerSlug: issuer.slug });

  return (
    <DirectSitesPageLayoutJumpLinks
      headerComponent={
        <HeaderWithoutSubscription
          title={props.programWithRelatedContent.data.record.program.name}
          viewAllRoute={O.some({
            urlInterface: SR.issuersitesBondProgramsControllerBondPrograms({ issuerId: issuer.id, issuerSlug: issuer.slug }),
            itemType: O.none,
          })}
          taggedContent={O.none}
        />
      }
      sidebarContent={
        <>
          <ProgramSidebarLinks
            jumplinks={sidebarLinks}
            program={props.programWithRelatedContent}
            programRouteMeta={programRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("pt-0")}>
        {pipe(
          overviewLink.enabled(iffs),
          trueOrEmpty(
            <ProgramOverviewSection
              documentsDataO={documentsDataO}
              linksDataO={linksDataO}
              program={props.programWithRelatedContent.data.record}
            />
          )
        )}
        {pipe(
          bondsDataO,
          mapOrEmpty(items => {
            const l = jl.program.bonds(props.programWithRelatedContent.data.record.relatedContent.offerings);
            return <BondsSection
              itemName={l.text(pages)}
              deals={items}
              sectionId={l.sectionId}
            />;
          })
        )}
        {pipe(
          rfpsDataO,
          mapOrEmpty(items => {
            const l = jl.program.rfps(props.programWithRelatedContent.data.record.relatedContent.rfps);
            return <RfpsSection
              deals={items}
              itemName={l.text(pages)}
              sectionId={l.sectionId}
            />;
          })
        )}
        {pipe(
          ratingsDataO,
          mapOrEmpty(ratings =>
            <RatingsSection
              programName={props.programWithRelatedContent.data.record.program.name}
              ratings={ratings}
              documentDownloadRoute={documentDownloadRoute}
            />
          )
        )}
        {pipe(
          cusip9sDataO,
          mapOrEmpty(cusips =>
            <AccentDividerSection
              jumpLink={jl.program.cusip9s(props.programWithRelatedContent.data.record.relatedContent.cusip9s)}
              pages={pages}
            >
              <CusipsTable cusips={cusips} />
            </AccentDividerSection>
          )
        )}
        {pipe(
          relatedContentLink.enabled(iffs),
          trueOrEmpty(
            <RelatedContentSection>
              <SharedRelatedContent
                events={eventsDataO}
                news={newsDataO}
                projects={projectsDataO}
              />
            </RelatedContentSection>
          )
        )}
      </div>
    </DirectSitesPageLayoutJumpLinks>
  );
};
